.darkModeToggle {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccd0da;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 5px;
  cursor: pointer;
  z-index: 999;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: opacity(80%);
  }
}
