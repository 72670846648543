@use "../../global.scss" as *;

.home {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  color: black;
  :global(.dark) & {
    color: white;
  }

  font-family: $font-sans-serif;
  font-weight: lighter;

  h1 {
    font-size: 6rem;
    font-weight: lighter;
    text-align: center;
    text-shadow: 0px 5px 3px #0003;
    :global(.dark) & {
      text-shadow: 0px 5px 3px #000;
    }
  }

  div {
    display: flex;
    gap: 10px;
  }

  @media only screen and (max-width: 800px) {
    gap: 10px;

    h1 {
      font-size: 5rem;
    }
  }
}
